import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { DeleteOutlineOutlined as DeleteIcon } from '@material-ui/icons'
import {
  ContractType,
  ContractValueType,
  DurationOptions,
  FuelType,
  IAdminCustomer,
  IContractCreationData,
  IContractOptionResponse,
  IContractTemplateResponse,
  IGenericContractTemplateResponse,
  INoContractsAvailableErrorParams,
  IProductContractOptionResponse,
  IProductContractTemplateResponse,
  IRegistrationNumberResponse,
  ITemplateLookupRequest,
  ProductAlongItsContracts,
  ResponseErrors,
  TIsoCountry,
  UserRole,
  Vehicle,
  VehicleAlongItsContracts,
  VehicleContracts,
  VehiclePartial,
} from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import {
  getProductTemplates,
  getTemplatesFromVehicle,
  getTemplatesVehicleLookup,
  lookupVehicleBlacklist,
} from 'api/api'
import classNames from 'classnames'
import ContractFlowDurationHourService from 'components/admin/Contract/Flow/DurationHourService'
import ContractFlowDurationMileageVer1 from 'components/admin/Contract/Flow/DurationMileageVer1'
import ContractFlowDurationMileageVer2, {
  IS_DEBUG_SLIDER_PRINT,
} from 'components/admin/Contract/Flow/DurationMileageVer2'
import ContractFlowOptions from 'components/admin/Contract/Flow/Options'
import ContractFlowProductForm from 'components/admin/Contract/Flow/Other/Form'
import ContractFlowStartValues from 'components/admin/Contract/Flow/Other/StartValues'
import ContractFlowStartDate from 'components/admin/Contract/Flow/StartDate'
import ContractFlowStartMileage from 'components/admin/Contract/Flow/StartMileage'
import ContractFlowTemplate from 'components/admin/Contract/Flow/Template'
import ContractFlowVehicleDetails from 'components/admin/Contract/Flow/Vehicle/Details'
import ContractFlowVehicleForm from 'components/admin/Contract/Flow/Vehicle/Form'
import ContractFlowVehicleLookup from 'components/admin/Contract/Flow/Vehicle/Lookup'
import VehicleMapperForm, { FormModeTypes } from 'components/admin/Contract/Flow/Vehicle/VehicleMapperForm'
import WarningMessage from 'components/admin/Contract/Flow/WarningMessage'
// import LoadingOverlay from 'components/LoadingOverlay' // NOTE: (!) LINE DISABLED FOR NOW, DUE TO A GFX BUG
import { LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import { PageNavigation } from 'components/Mui/Page'
import { ITab } from 'components/Mui/Page/PageNavigation'
import LoadingComponent from 'components/TableLoadingIndicator'
import moment from 'moment-timezone'
import React from 'react'
import {
  contractFlowInitialState,
  customContractFlowInitialState,
  productContractFlowInitialState,
} from 'reducers/contractFlow/contractFlowInitialState'
import { AppContext } from 'store/appContext'
import { theme } from 'theme'
import { t, tErrorComment } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import {
  ContractFlowActivePanel,
  FreeContractTab,
  IContractFlow,
  IContractFlowMileageDuration,
  ICustomPrice,
} from 'types/contractFlow'
import { trackEvent } from 'utils/analytics'
import { addMonths, defaultDateFormat, getContractProductAge, getProductAge, sleep, validDate } from 'utils/date'
import {
  getClosestAllowedValue,
  getDurationList,
  getDurationValues,
  getMileageList,
  getMileageValues,
} from 'utils/durationMileage'
import { getProvider } from 'utils/localStorage'
import { getMapSize } from 'utils/map'
import { assert, debugPrint } from 'utils/miscs'
import errors from 'utils/notify/errors'
import notify from 'utils/notify/notify'
import { hasItemSerialNumber, productHasRequiredInfo } from 'utils/product'
import { REQUIRED_VIN_LENGTH } from 'utils/regex'
import { tDot } from 'utils/string'
import {
  hasRequiredInfo,
  hasVinAndRegNum,
  partialVehicleToVehicle,
  partialVehicleToVehicleAlongItsContracts,
} from 'utils/vehicle'
import { IS_DEBUG_CALCULATE_PRICE_PRINT } from '../../../../components/admin/Contract/Flow/Payments/index'
import EnginePowerRequestDialog from '../../../../components/EnginePowerRequestDialog'
import { isContractReadyForCalculation } from '../contractFlowFunctions/readyForCalculation'
import PaymentsColumn from './PaymentsColumn'

export interface IReturnArgsVehicleLicenseLookup {
  error: string
  errorKey?: TranslationKey
  errorTranslationReplacements?: { [key in string]: string }
  comment?: string
}

// TODO: Move this to CONFIG, so it's easier to find in the future.
const DEFAULT_MILEAGE_NEW_VEHICLE: number = 1

const isCheckPaymentTypesOnAllContractsUponLookup: boolean = false // If true will check that all contracts has at least on payment type.

// Allowed over-mileage/age for contracts where the limit is on the options
const VERY_LARGE_INT = Math.round(Number.MAX_SAFE_INTEGER / 2) // So we can add smth without overflow
const maxOverAgeCustom = 0
const maxOverMileageCustom = 0
const maxOverAgeStandard = VERY_LARGE_INT
const maxOverMileageStandard = VERY_LARGE_INT
const restrictOptionsOnOverAgeMileage = false
const adjustSlidersOnContractLimits = false
const adjustSlidersOnOptionLimits = false

type TShownMainComponent = 'LookupForm' | 'VehicleDetails' | 'VehicleForm' | 'ModelMapper' | 'Loading' | 'ProductForm'

interface IDurationMileageValues {
  duration: IContractFlowMileageDuration
  mileage: IContractFlowMileageDuration
}

interface IOwnProps {
  activePanel: ContractFlowActivePanel // The progress, which panel we are on currently.
  activeTab?: FreeContractTab
  userRole: UserRole
  contract: IContractFlow
  creationData: IContractCreationData | undefined
  productContract: boolean
  freeContract: boolean
  prettyIdentifier: string | undefined
  onActivePanelChange: (activePanel: ContractFlowActivePanel) => void
  onChange: (values: Partial<IContractFlow>) => any
  onFreeContract: () => void
  onFreeContractClick: () => void
  onNext: () => void
  onResetContract: () => void
  onContractUpdate: () => void
  onCustomerChange: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange: (locked: boolean) => void
  onWarrantyCreated: () => void
  onActiveTabChange: (newTab?: FreeContractTab) => void
  valid: { customer: boolean }
  vehicleUpdates: number
  customerUpdates: number
  contractUpdates: number
  warrantyCreated: boolean
  loading: boolean
  productFuelTypes: FuelType[] | undefined
  hasAvailableWarranties: boolean
  locale: string
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  templateShouldUpdate: boolean
  isLoading: boolean
  shownMainComponent: TShownMainComponent | undefined
  formModeType: FormModeTypes
  resettingForm: boolean
  valueType: ContractValueType
  hasCalculated: boolean
  detachedValueType: boolean
  defaultContractMileageFreeContract?: number
  lookupCountry: TIsoCountry | undefined
  vehicleIsBlacklisted: boolean
  provider: IContractProviderInfo | undefined
  prevStartMileage: number | undefined
  isShowDialogEnginePower: boolean
  isLookupFieldErrorState: boolean
  hasAxBaseTemplate: boolean
}

const initialState: IState = {
  isLoading: false,
  shownMainComponent: undefined,
  formModeType: 'default',
  resettingForm: false,
  valueType: undefined,
  hasCalculated: false,
  templateShouldUpdate: true,
  detachedValueType: false,
  defaultContractMileageFreeContract: undefined,
  lookupCountry: undefined,
  vehicleIsBlacklisted: false,
  provider: undefined,
  prevStartMileage: undefined,
  isShowDialogEnginePower: false,
  isLookupFieldErrorState: false,
  hasAxBaseTemplate: false,
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    paymentsColumn: {
      marginTop: 40,
      position: 'sticky',
      top: '24px',
    },
    paymentsColumnContainer: {
      position: 'relative',
    },
    icon: {
      fontSize: 14,
    },
    iconRight: {
      marginLeft: spacing(1),
    },
    customerAndPaymentButton: {
      marginRight: spacing(1),
    },
    loadingComponentContainer: {
      paddingTop: spacing(2),
      paddingBottom: spacing(4),
      marginLeft: '45%',
    },
    conditions: {
      height: '20wv',
    },
    tabs: {
      paddingLeft: '9px',
    },
    tabHeight: {
      height: '40px',
    },
    tab: {
      backgroundColor: theme.palette.freeContract[500],
      color: theme.palette.text.dark,
    },
    activetab: {
      borderColor: theme.palette.freeContract[500],
      backgroundColor: 'white',
    },
    freeContractActive: {
      borderLeftColor: theme.palette.freeContract[500],
    },
  })

const detachedValueTypes: ContractValueType[] = ['Services']

class ContractFlowPageDetails extends React.Component<TProps, IState> {
  public state: IState = initialState

  constructor(props: TProps) {
    super(props)

    const provider: IContractProviderInfo | undefined = getProvider()
    if (provider) {
      this.state.provider = provider
    } else {
      throw Error('ContractFlowPageDetails: Failed loading provider')
    }
  }

  public async componentDidMount() {
    this.decideMainComponent()

    const { contract, freeContract } = this.props
    if (contract.isCopiedContract && contract.contractObjectType === 'Product' && contract.template) {
      this.loadOptions()
    }

    if (
      !contract.isCopiedContract &&
      freeContract &&
      contract.contractObjectType === 'Vehicle' &&
      contract.vehicleAlongItsContracts &&
      'vin' in contract.vehicleAlongItsContracts &&
      contract.vehicleAlongItsContracts.brand
    ) {
      const { brand, model, fuelType } = contract.vehicleAlongItsContracts
      const res = await lookupVehicleBlacklist({ brand: brand.name, model: model.name, fuelType: fuelType.name })

      if (res.data !== undefined && res.data !== null) {
        !!res.data && notify.warning({ message: t(errors['REGNO_NO_CONTRACTS_AVAILABLE_BLACKLISTED']) })
        this.setState({ vehicleIsBlacklisted: !!res.data }, () => {
          contract.state.active !== ContractFlowActivePanel.options && this.handleTemplateChange(undefined)
        })
      }
    }
  }

  public async componentDidUpdate(oldProps: TProps) {
    const newProps: TProps = this.props
    const { provider, isShowDialogEnginePower } = this.state

    if (provider?.isUsingV4PricingTool) {
      const newStartMileage: number | undefined = newProps.contract.startMileage
      const oldStartMileage: number | undefined = oldProps.contract.startMileage
      const newEnginePower: number | undefined = (newProps.contract.product as Vehicle)?.engineMaxPower
      const oldEnginePower: number | undefined = (oldProps.contract.product as Vehicle)?.engineMaxPower

      let isTriggerVehicleLicenseLookup = false

      // if use another reg number, reset the state
      if (newProps.contract.vehicleLookedUp?.regNumber !== oldProps.contract.vehicleLookedUp?.regNumber) {
        this.setState(initialState)
      }

      if (newStartMileage !== oldStartMileage) {
        debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '! - Detected new StartMileage = ' + newStartMileage)
        isTriggerVehicleLicenseLookup = true
      }
      if (newEnginePower !== oldEnginePower) {
        debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, '! - Detected new EnginePower = ' + newEnginePower)
        isTriggerVehicleLicenseLookup = true
      }

      if (isTriggerVehicleLicenseLookup) {
        const regNumberOrVIN: string =
          newProps.contract.vehicleLookedUp?.regNumber || newProps.contract.vehicleLookedUp?.vin || ''
        this.handleVehicleLicenseLookup(regNumberOrVIN, newStartMileage || null)
      }

      if (newProps.activePanel > ContractFlowActivePanel.vehicle) {
        const newEngineMaxPower: number = newProps.contract.vehicleLookedUp?.engineMaxPower || 0

        const isCaravan: boolean =
          newProps.contract.contractObjectType === 'Vehicle' &&
          newProps.contract.product &&
          'vin' in newProps.contract.product &&
          newProps.contract.product.vehicleType === 'Caravan'

        if (
          !isShowDialogEnginePower &&
          !newProps.contract.state.hasGivenEnginePower &&
          !newEngineMaxPower &&
          !isCaravan
        ) {
          this.setState({ isShowDialogEnginePower: true })
        }
      }
    }

    const newVehicleLookedUp: VehiclePartial | undefined = newProps.contract.vehicleLookedUp
    const oldVehicleLookedUp: VehiclePartial | undefined = oldProps.contract.vehicleLookedUp

    const vehicleFoundInPricefileAtStartup = newProps.contract.state.vehicleLookedUpFoundInPricefile
    const areAllTemplatesDisabled: boolean = !!(newProps.creationData && newProps.creationData.templatesAllDisabled)

    let isRedecideMainComponent: boolean = false

    if (newVehicleLookedUp && !oldVehicleLookedUp) {
      const newVehicle: VehicleAlongItsContracts = partialVehicleToVehicleAlongItsContracts(newVehicleLookedUp)
      const hasVehicleRequiredInfo: boolean = hasRequiredInfo(newVehicle)

      hasVehicleRequiredInfo && this.handleVehicleChange(newVehicle)

      if (vehicleFoundInPricefileAtStartup && !areAllTemplatesDisabled) {
        hasVehicleRequiredInfo && this.handleTemplatesLookup(newVehicle)
      }
    }

    // ---------------------------------------------------------------------------------------------

    const newFlowType = newProps.contract.flowType
    const oldFlowType = oldProps.contract.flowType

    if (newFlowType === 'ADJUST' && oldFlowType !== 'ADJUST') {
      isRedecideMainComponent = true
    }

    // ---------------------------------------------------------------------------------------------

    const newActivePanel: ContractFlowActivePanel = newProps.activePanel
    const oldActivePanel: ContractFlowActivePanel = oldProps.activePanel

    // Allow the main component, advance from search component 'LookupForm' to a new component.
    if (newActivePanel && oldActivePanel === ContractFlowActivePanel.searchVehicle) {
      isRedecideMainComponent = true
    }

    // If user "Restarts" the contract creation - Allow this
    // class to switch the main component back to 'LookupForm'.
    if (
      newActivePanel === ContractFlowActivePanel.searchVehicle &&
      oldActivePanel > ContractFlowActivePanel.searchVehicle
    ) {
      isRedecideMainComponent = true
    }

    // ---------------------------------------------------------------------------------------------

    isRedecideMainComponent && this.decideMainComponent()
  }

  private getHeaderTabs = (): Array<ITab<FreeContractTab>> => {
    return [
      { title: t('Vehicle'), name: 'VEHICLE', e2e: 'contract-flow-page__tabs--vehicle' },
      { title: t('Product'), name: 'PRODUCT', e2e: 'contract-flow-page__tabs--product' },
    ]
  }

  public render() {
    const {
      activePanel,
      activeTab,
      userRole,
      contract,
      creationData,
      freeContract,
      onFreeContract,
      prettyIdentifier,
      classes,
      onChange,
      productFuelTypes,
      hasAvailableWarranties,
    } = this.props

    const isParentLoading = this.props.loading
    const {
      isLoading,
      shownMainComponent,
      formModeType,
      hasCalculated,
      templateShouldUpdate,
      resettingForm,
      vehicleIsBlacklisted,
      provider,
      isShowDialogEnginePower,
      isLookupFieldErrorState,
      hasAxBaseTemplate,
    } = this.state

    let isShowLoadingComponent: boolean = false

    if (isParentLoading || isLoading) {
      // NOTE: When there is some extensive delay (when more data needs to be loaded). Instead of
      // showing inaccurate info and components during loading, shows loading progress instead.
      // For ex:
      //   - When extending a contract (isParentLoading),
      //   - Or reloading templates (isLoading).
      isShowLoadingComponent = true
    }

    const hasVehicleRequiredInfo: boolean =
      contract.contractObjectType === 'Vehicle' && contract.product && 'vin' in contract.product
        ? hasRequiredInfo(contract.product)
        : false // Is the Vehicle in contract valid?
    const hasProductRequiredInfo: boolean =
      contract.contractObjectType === 'Product' &&
      contract.product &&
      contract.product &&
      'serialNumber' in contract.product
        ? productHasRequiredInfo(contract.product)
        : false

    const areAllTemplatesDisabled: boolean = !!(creationData && creationData.templatesAllDisabled)

    const freeContractsAvailable =
      (provider && provider.isForceEnableCustomContracts) ||
      (!!creationData && !!creationData.templates && !!creationData.templates.length)
    const contractEndMileage = (contract.startMileage || 0) + contract.mileage.value
    const contractEndAge = getContractProductAge(contract) + contract.duration.value
    const freeContractTemplates = freeContract && !vehicleIsBlacklisted ? this.availableTemplatesForFreeContract() : []
    const maxOverAge = restrictOptionsOnOverAgeMileage
      ? freeContract
        ? maxOverAgeCustom
        : maxOverAgeStandard
      : VERY_LARGE_INT
    const maxOverMileage = restrictOptionsOnOverAgeMileage
      ? freeContract
        ? maxOverMileageCustom
        : maxOverMileageStandard
      : VERY_LARGE_INT

    const isoCountry: any = !(provider && provider.country) ? null : (provider.country as string)

    // const isAllDataAvailable =
    //   !isShowLoadingComponent || (this.isAllDataAvailable(contract) && !contract.invalidPaymentData)
    const isAllDataAvailable = true
    const nextButtonDisabledWarning = this.getNextButtonDisabledWarning(isAllDataAvailable)
    const allowNext = isAllDataAvailable && hasCalculated && !nextButtonDisabledWarning && userRole !== 'observer'

    const tabs = this.getHeaderTabs()
    const isCopiedContract = contract.isCopiedContract
    const disabledValueTypes: ContractValueType[] = this.state.detachedValueType ? ['Services'] : []

    const isMileageDisabled: boolean = !!contract?.state?.isMileageDisabled

    const isCaravan: boolean =
      contract.contractObjectType === 'Vehicle' &&
      contract.product &&
      'vin' in contract.product &&
      contract.product.vehicleType === 'Caravan'

    // tslint:disable jsx-no-lambda
    return (
      <>
        <LayoutRow columns={2}>
          <LayoutColumn>
            <AppContext.Consumer>
              {({ providerInfo }) => (
                <>
                  {shownMainComponent === 'LookupForm' && (
                    <ContractFlowVehicleLookup
                      onVehicleLicenseLookup={this.handleVehicleLicenseLookup}
                      onSkip={this.handleVehicleLookupSkip}
                      onFreeContract={onFreeContract}
                      userRole={userRole}
                      flowType={contract.flowType}
                      showHeader={!(providerInfo && providerInfo.productsEnabled)}
                      isFreeContract={freeContract}
                      freeContractsAvailable={freeContractsAvailable}
                      areAllTemplatesDisabled={areAllTemplatesDisabled}
                    />
                  )}
                  {shownMainComponent === 'VehicleDetails' && (
                    <ContractFlowVehicleDetails
                      isUsingV4PTAsSource={provider?.isUsingV4PricingTool || false}
                      vehicle={contract.product}
                      flow={contract.flowType}
                      // freeContract={freeContract}
                      onEditEnginePower={() => this.setState({ isShowDialogEnginePower: true })}
                    />
                  )}
                  {(shownMainComponent === 'VehicleForm' || shownMainComponent === 'ProductForm') &&
                    !isCopiedContract &&
                    providerInfo &&
                    providerInfo.productsEnabled && (
                      <PageNavigation
                        className={classNames(classes.tabHeight, classes.tabs)}
                        tabClassName={classNames(classes.tab, classes.tabHeight)}
                        activeTabClassName={classes.activetab}
                        active={activeTab}
                        onChange={this.handleTabChange}
                        tabs={tabs}
                      />
                    )}
                  {shownMainComponent === 'VehicleForm' && (
                    <ContractFlowVehicleForm
                      freeContract={freeContract}
                      onChange={this.handleVehicleChange}
                      resetIcon={DeleteIcon}
                      vehicleAlongItsContracts={contract.product}
                      contractType={contract.contractType}
                      cars={creationData && creationData.cars}
                      isoCountry={isoCountry}
                      onTemplatesLookup={this.handleTemplatesLookup}
                      showHeader={!(providerInfo && providerInfo.productsEnabled)}
                      onFormReset={this.handleVehicleFormReset}
                      freeContractActiveClass={classes.freeContractActive}
                      resettingForm={resettingForm}
                      flowType={contract.flowType}
                    />
                  )}
                  {shownMainComponent === 'ProductForm' && providerInfo && providerInfo.productsEnabled && (
                    <ContractFlowProductForm
                      onChange={this.handleProductChange}
                      resetIcon={DeleteIcon}
                      productAlongItsContracts={contract.product}
                      contractType={contract.contractType}
                      fuelTypes={productFuelTypes}
                      onTemplatesLookup={this.handleTemplatesLookup}
                      freeContract={freeContract}
                      showHeader={isCopiedContract}
                      onFormReset={this.handleProductFormReset}
                      freeContractActiveClass={classes.freeContractActive}
                    />
                  )}
                  {shownMainComponent === 'ModelMapper' && (
                    <VehicleMapperForm
                      formModeType={formModeType}
                      vehicle={contract.product}
                      vehicleLookedUp={contract.vehicleLookedUp}
                      cars={creationData && creationData.cars}
                      isoCountry={isoCountry}
                      onVehicleChange={this.handleVehicleChange}
                      onTemplatesLookup={this.handleTemplatesLookup}
                      hasAvailableWarranty={hasAvailableWarranties}
                    />
                  )}
                  {/* NOTE: (!) LINE DISABLE FOR NOW, DUE TO A GFX BUG {isShowLoadingComponent && isLoading && <LoadingOverlay open={true} />} */}
                  {isShowLoadingComponent && (isParentLoading || isLoading) && (
                    <div className={classes.loadingComponentContainer}>
                      <LoadingComponent loading={true} />
                    </div>
                  )}
                  {freeContract &&
                  shownMainComponent === 'ProductForm' &&
                  providerInfo &&
                  providerInfo.productsEnabled ? (
                    // For object products.
                    <ContractFlowStartValues
                      active={activePanel}
                      activeEnum={ContractFlowActivePanel.startMileage}
                      onValueChange={this.handleStartValueChange}
                      onValueTypeChange={this.handleValueTypeChange}
                      hidden={false}
                      startValue={contract.startValue}
                      valueType={contract.startValueType}
                      freeContract={freeContract}
                      disabledValueTypes={disabledValueTypes}
                    />
                  ) : (
                    !isMileageDisabled && (
                      <ContractFlowStartMileage
                        active={activePanel}
                        activeEnum={ContractFlowActivePanel.startMileage}
                        onStartMileageChange={this.handleStartMileageChange}
                        hidden={false}
                        startMileageValue={contract.startMileage}
                        isUsingV4PTAsSource={providerInfo?.isUsingV4PricingTool}
                        freeContract={freeContract}
                        flowType={contract.flowType}
                      />
                    )
                  )}
                  <ContractFlowTemplate
                    className={classes.conditions}
                    flow={contract.flowType}
                    active={areAllTemplatesDisabled ? 0 : activePanel}
                    activeEnum={ContractFlowActivePanel.template}
                    isKeepDisabled={areAllTemplatesDisabled}
                    validVehicle={hasVehicleRequiredInfo}
                    onChange={this.handleTemplateChange}
                    templates={
                      freeContract && creationData && freeContractTemplates
                        ? this.filterFreeTemplates(freeContractTemplates)
                        : contract.state.templates
                    }
                    templateCurrent={contract.template}
                    freeContract={freeContract}
                    contract={contract}
                    shouldUpdate={templateShouldUpdate}
                  />
                  <ContractFlowStartDate
                    active={activePanel}
                    activeEnum={ContractFlowActivePanel.durationMileage}
                    isHidden={contract.v4ProductType !== 'Warranty'}
                    contractStartDateValue={contract.contractStartDateISO}
                    onStartDateChange={this.handleStartDateChange}
                    freeContract={freeContract}
                    flowType={contract.flowType}
                  />
                  {freeContract &&
                  shownMainComponent === 'ProductForm' &&
                  providerInfo &&
                  providerInfo.productsEnabled ? (
                    // For object products.
                    <ContractFlowDurationHourService
                      active={activePanel}
                      activeEnum={ContractFlowActivePanel.durationMileage}
                      durations={contract.state.durations}
                      freeContract={freeContract}
                      onChange={this.handleDurationHourServiceChange}
                      template={contract.template}
                      value={{
                        duration: contract.duration,
                        selectedValue: contract.value,
                        valueType: contract.valueType,
                      }}
                    />
                  ) : (
                    this.renderContractFlowDurationMileage(provider?.isUsingV4PricingTool)
                  )}
                </>
              )}
            </AppContext.Consumer>
            <ContractFlowOptions
              active={activePanel}
              activeEnum={ContractFlowActivePanel.options}
              onChange={this.handleOptionsChange}
              options={contract.state.options}
              value={contract.options}
              freeContract={freeContract}
              contractEndMileage={contractEndMileage - maxOverMileage}
              contractEndAge={contractEndAge - maxOverAge}
            />
          </LayoutColumn>

          <div className={classes.paymentsColumnContainer}>
            <LayoutColumn className={classes.paymentsColumn}>
              <PaymentsColumn
                style={{ marginTop: '24px' }}
                active={activePanel}
                userRole={userRole}
                contract={contract}
                freeContract={freeContract}
                hasVehicleRequiredInfo={!!hasVehicleRequiredInfo || !!hasProductRequiredInfo}
                readyForCalculation={this.readyForCalculation}
                onContractChange={onChange}
                onResetContractClick={this.handleResetContract}
                prettyIdentifier={prettyIdentifier}
                paymentGateways={creationData ? creationData.payment.paymentGateways : []}
                nextButtonDisabledWarning={nextButtonDisabledWarning}
                onCustomerChange={this.props.onCustomerChange}
                onCustomerLockedChange={this.props.onCustomerLockedChange}
                onWarrantyCreated={this.handleWarrantyCreated}
                valid={this.props.valid}
                creationData={creationData}
                contractUpdates={this.props.contractUpdates}
                vehicleUpdates={this.props.vehicleUpdates}
                customerUpdates={this.props.customerUpdates}
                allowNext={allowNext}
                onNext={this.props.onNext}
                locale={this.props.locale}
              />
              {hasAxBaseTemplate && activePanel && (
                <WarningMessage message={t('Incorrect price or any questions contact Autoexperten')} />
              )}
            </LayoutColumn>
          </div>
        </LayoutRow>
        {isShowDialogEnginePower && !isLookupFieldErrorState && !isCaravan && (
          <EnginePowerRequestDialog
            isUsingV4PTAsSource={provider?.isUsingV4PricingTool}
            initialEnginePowerKW={(contract.product as any)?.engineMaxPower || null}
            lockedMinEnginePowerKW={(contract.product as any)?.originalEnginePowerKWFromLookup || null}
            onConfirm={(userGivenEnginePowerKW: number | null) => this.handleEnginePowerConfirm(userGivenEnginePowerKW)}
            onCancel={() => this.handleEnginePowerCancel()}
          />
        )}
      </>
    )
  }

  private handleEnginePowerConfirm = async (userGivenEnginePowerKW: number | null) => {
    const { contract } = this.props

    debugPrint(
      IS_DEBUG_CALCULATE_PRICE_PRINT,
      'HandleEnginePowerConfirm(..): userGivenEnginePowerKW = ' + userGivenEnginePowerKW,
    )

    this.setState({ isShowDialogEnginePower: false })
    await this.props.onChange({
      product: { ...contract.product, engineMaxPower: userGivenEnginePowerKW || 0 },
      state: {
        ...contract.state,
        hasGivenEnginePower: true,
        userGivenEnginePowerKW: userGivenEnginePowerKW,
      },
    })
  }

  private handleEnginePowerCancel = async () => {
    const { contract } = this.props

    this.setState({ isShowDialogEnginePower: false })
    await this.props.onChange({
      state: {
        ...contract.state,
        hasGivenEnginePower: false,
      },
    })
  }

  private renderContractFlowDurationMileage = (isUsingV4PricingTool: boolean | undefined) => {
    const { activePanel, contract, freeContract } = this.props
    const { defaultContractMileageFreeContract } = this.state

    if (!isUsingV4PricingTool) {
      // assert(
      //   !!(contract?.state?.durations && contract.state.durations.length > 0),
      //   'No DurationOptions[] for ContractFlowDurationMileageVer1 (using legacy contracts)',
      // )

      return (
        <ContractFlowDurationMileageVer1
          active={activePanel}
          activeEnum={ContractFlowActivePanel.durationMileage}
          durations={contract.state.durations}
          freeContract={freeContract}
          onChange={this.handleDurationMileageVer1Change}
          template={contract.template}
          value={{ mileage: contract.mileage, duration: contract.duration }}
          defaultContractMileageFreeContract={defaultContractMileageFreeContract}
        />
      )
    } else {
      const mileageDurationsMap = contract.state?.mileageDurationsMap || null
      // if (!mileageDurationsMap)
      //   throw Error('Cannot find required mileageDurationsMap when using V4PricingTool contracts')
      // assert(
      //   !!mileageDurationsMap,
      //   'No mileageDurationsMap for ContractFlowDurationMileageVer2 (using V4PricingTool contracts)',
      // )

      return (
        <ContractFlowDurationMileageVer2
          active={activePanel}
          activeEnum={ContractFlowActivePanel.durationMileage}
          mileageDurationsMap={mileageDurationsMap}
          freeContract={freeContract}
          onChange={this.handleDurationMileageVer2Change}
          selectedTemplateName={contract.template?.name}
          durationMileageValues={{ mileage: contract.mileage, duration: contract.duration }}
          isMileageDisabled={contract.state.isMileageDisabled}
          defaultContractMileageFreeContract={defaultContractMileageFreeContract}
          defaultMileageKm={contract.template?.defaultMileage || 0}
          defaultDurationMonths={contract.template?.defaultDuration || 0}
          contractFlow={contract}
        />
      )
    }
  }

  private loadOptions = () => {
    const { contract } = this.props

    if (contract.template) {
      this.props.onChange(contract)
    }
  }

  /**
   * Handle VehicleLookup.
   */
  private handleVehicleLicenseLookup = async (
    regNoOrVIN: string,
    startMileage: number | null,
  ): Promise<IReturnArgsVehicleLicenseLookup> => {
    this.setState({ isLookupFieldErrorState: true }) // Setting this to true to begin with, if no error resetting to false later on.
    const possibleUserGivenEnginePowerKW: undefined | number = (this.props.contract.product as Vehicle)?.engineMaxPower
    debugPrint(
      IS_DEBUG_CALCULATE_PRICE_PRINT,
      '!! handleVehicleLicenseLookup, possibleKW: ' + possibleUserGivenEnginePowerKW,
    )

    const lookup = await getTemplatesVehicleLookup(regNoOrVIN, startMileage || 1, possibleUserGivenEnginePowerKW)

    if (lookup.statusCode === 200 && lookup.data && lookup.data.lookup.vehicle) {
      trackEvent('Contract offer', 'Vehicle lookup success')

      const didFindCompleteVehicleOnLookup: boolean = true

      const lookupResponse: IRegistrationNumberResponse = lookup.data
      const vehicle: Vehicle = lookupResponse.lookup.vehicle
      const lookupCountry: TIsoCountry = lookupResponse.lookup.lookupCountry as TIsoCountry

      if (!lookup.data.lookup.vehicle.fuelType.name) {
        const errorType = 'FUEL_TYPE_NOT_SUPPORTED'
        const returnArgs: IReturnArgsVehicleLicenseLookup = {
          error: t(errors[errorType]),
        }
        return returnArgs
      }

      if (this.props.contract.state.hasGivenEnginePower) {
        vehicle.engineMaxPower = this.props.contract.state?.userGivenEnginePowerKW || 0
      }

      // TODO: Move and make its own method for this like, mapRegistrationNumberResponseToVehicleAlongItsContracts(..)
      const vehicleAlongItsContracts: VehicleAlongItsContracts | undefined = {
        ...vehicle,
        contracts: lookupResponse.contracts,
        hasActiveWarranty: lookupResponse.hasActiveWarranty,
      }

      const vehiclePartial: VehiclePartial = {
        vin: !vehicle.vin ? '' : vehicle.vin,
        regNumber: vehicle.regNumber,
        brand: vehicle.brand,
        model: vehicle.model,
        modelYear: vehicle.modelYear,
        fuelType: vehicle.fuelType,
        regDate: vehicle.regDate,
        vehicleType: vehicle.vehicleType,
        engineMaxPower: vehicle.engineMaxPower,
        originalEnginePowerKWFromLookup: vehicle.originalEnginePowerKWFromLookup,
        cylinderVolume: vehicle.cylinderVolume,
        transmissionType: vehicle.transmissionType,
        driveType: vehicle.driveType,
        hasFourWheelDrive: vehicle.hasFourWheelDrive,
      }

      this.handleVehicleLookupFound(
        vehiclePartial,
        didFindCompleteVehicleOnLookup,
        vehicleAlongItsContracts,
        lookupCountry,
      )
      this.setState({
        hasAxBaseTemplate: !!lookupResponse.contracts?.contracts?.some((c) => c.priceSource === 'Autoexperten'),
      })
    } else if (
      lookup.errorData &&
      lookup.errorData.message === 'REGNO_NO_CONTRACTS_AVAILABLE' &&
      lookup.errorData.params
    ) {
      trackEvent('Contract offer', 'Vehicle lookup success') // @todo: Hmm, recheck that this is correct event.

      const didFindCompleteVehicleOnLookup: boolean = false
      const errorParams: INoContractsAvailableErrorParams = lookup.errorData.params
      const vehiclePartial: VehiclePartial = errorParams.matchedData
      const lookupCountry: TIsoCountry | undefined = lookup.errorData.params.lookupCountry?.isoName as TIsoCountry
      const provider: IContractProviderInfo | undefined = getProvider()

      if (vehiclePartial.vin && vehiclePartial.vin.length === REQUIRED_VIN_LENGTH) {
        if (provider?.isUsingV4PricingTool && lookup.errorData.message === 'REGNO_NO_CONTRACTS_AVAILABLE') {
          // Finds no contracts for a V4PricingTool contract.
          const errorType = 'REGNO_NO_CONTRACTS_AVAILABLE'

          this.handleNoContractsFoundOnReLookup('REGNO_NO_CONTRACTS_AVAILABLE')
          const returnArgs: IReturnArgsVehicleLicenseLookup = {
            error: t(errors[errorType]),
          }
          return returnArgs
        } else {
          let vehicleAlongNoneContracts: VehicleAlongItsContracts = partialVehicleToVehicleAlongItsContracts(
            vehiclePartial,
          )

          vehicleAlongNoneContracts.contracts = undefined
          vehicleAlongNoneContracts.hasActiveWarranty = lookup.data?.hasActiveWarranty

          this.handleVehicleLookupFound(
            vehiclePartial,
            didFindCompleteVehicleOnLookup,
            vehicleAlongNoneContracts,
            lookupCountry,
          )
        }
      } else {
        const errorType = 'VIN_NOT_OF_REQUIRED_LENGTH'
        const returnArgs: IReturnArgsVehicleLicenseLookup = {
          error: t(errors[errorType]),
        }
        return returnArgs
      }
    } else if (lookup.networkError) {
      trackEvent('Contract offer', 'Vehicle lookup error')

      const returnArgs: IReturnArgsVehicleLicenseLookup = {
        error: errors[lookup.networkError] || errors.GENERIC_NETWORK_TIMEOUT,
      }
      return returnArgs
    } else {
      trackEvent('Contract offer', 'Vehicle lookup error')

      let error: any = ''
      let messageReplacements: any = ''

      if (lookup?.errorData?.message === 'REGNO_NO_CONTRACTS_AVAILABLE_FUELTYPE_NOT_SUPPORTED') {
        const fueltypeName: string = lookup?.errorData?.params?.matchedData?.fuelType?.name || 'Unknown'

        error = t('No contracts available for vehicle with this fuel type: %fueltypeName')
        messageReplacements = { fueltypeName: t(fueltypeName as TranslationKey) }
      } else {
        error = (lookup.errorData && errors[lookup.errorData.message]) || errors.GENERIC_ERROR
        messageReplacements = lookup.errorData && lookup.errorData.messageReplacements
      }
      const errorComment = messageReplacements?.comment

      return {
        error: t(error, messageReplacements),
        errorKey: error,
        errorTranslationReplacements: messageReplacements,
        comment: errorComment && tErrorComment(errorComment),
      }
    }
    this.setState({ isLookupFieldErrorState: false })
    return {
      error: '',
      errorKey: '',
      errorTranslationReplacements: undefined,
    }
  }

  private filterFreeTemplates = (templates: IGenericContractTemplateResponse[]) => {
    const { freeContract, contract } = this.props

    let isProduct = freeContract && contract.contractObjectType === 'Product'
    const filteredTemplates = templates.filter((t) => t.isProductTemplate === isProduct)
    return isProduct
      ? (filteredTemplates as IContractTemplateResponse[])
      : (filteredTemplates as IProductContractTemplateResponse[])
  }

  private handleTabChange = (tab: string) => {
    if (this.props && this.props.activeTab && tab) {
      let shownMainComponent: TShownMainComponent | undefined
      let activeTab: FreeContractTab | undefined

      switch (tab) {
        case 'PRODUCT':
          shownMainComponent = 'ProductForm'
          activeTab = tab
          this.handleProductFormReset()
          break
        case 'VEHICLE':
          shownMainComponent = 'VehicleForm'
          activeTab = tab
          this.handleVehicleFormReset()
          break
      }

      this.props.onActiveTabChange(activeTab)
      this.setState({ shownMainComponent })
    }
  }

  private handleProductFormReset = () => {
    const initialContract = productContractFlowInitialState

    this.props.onChange(initialContract)
    this.setState({ detachedValueType: false, vehicleIsBlacklisted: false }, () =>
      this.handleTemplateChange(undefined, initialContract),
    )
  }

  private handleVehicleFormReset = async () => {
    const initialContract = customContractFlowInitialState
    this.setState({ resettingForm: true })
    this.props.onChange(initialContract)

    // unnecessary call? caused input fields to be enabled
    //this.setState({ detachedValueType: false }, () => this.handleTemplateChange(undefined, initialContract))

    if (this.props.freeContract) {
      // This is needed because otherwise resettingForm will be set
      // to false before vehicle lookup has time to trigger, thus missing
      // this flag and updating the form with values from lookup
      sleep(300).then(() => {
        this.setState({ resettingForm: false, vehicleIsBlacklisted: false })
      })
    } else {
      this.setState({ resettingForm: false })
    }
  }

  private decideMainComponent = (): void => {
    const { contract, creationData, freeContract, productContract } = this.props
    const provider: IContractProviderInfo | undefined = getProvider()

    if (!provider) throw new Error('Cannot find provider info')

    let hasVehicleRequiredInfo: boolean = false

    if (contract.product) {
      hasVehicleRequiredInfo =
        'serialNumber' in contract.product
          ? productHasRequiredInfo(contract.product)
          : hasRequiredInfo(contract.product as Vehicle) // Is the Vehicle in contract valid?
    }

    const isCopiedContract: boolean = !!contract.isCopiedContract
    const vehicleFoundInPricefileAtStartup = this.props.contract.state.vehicleLookedUpFoundInPricefile
    const wasModelNameReMappedAtStartup = !!this.props.contract.state.wasModelNameReMappedAtStartup
    const isAllTemplatesDisabled: boolean = !!(creationData && creationData.templatesAllDisabled)

    let shownMainComponent: TShownMainComponent // The main component to show!
    let formModeType: FormModeTypes = 'default'
    let activeTab: FreeContractTab | undefined = undefined

    if (!contract.state.licenseLookup && !contract.state.licenseSkip && !freeContract) {
      // *** The Search Field ***
      shownMainComponent = 'LookupForm'
    } else if (contract.state.licenseSkip) {
      // *** The "Manual Search" Form ***
      // if (lookupCountry === 'NO') {
      //   shownMainComponent = 'VehicleDetails'
      // } else {
      shownMainComponent = 'ModelMapper'
      formModeType = 'Manual-Search'
      // }
    } else if (isAllTemplatesDisabled) {
      // *** All templates (subscriptions) are disabled (meaning that only Dealer Paid Warranties are offered (if they are enabled)) ***
      if (!hasVehicleRequiredInfo) {
        shownMainComponent = 'LookupForm' // Keep the user at the Lookup Form.
        notify.error({ message: t('Sorry, complete vehicle data not found from lookup service') })
      } else {
        // *** Show the Vehicle Info Card/Banner ***
        // Also hides the templates card (for selling only possible External Warranties).
        shownMainComponent = 'VehicleDetails'
      }
    } else if (productContract) {
      // *** The "Free/Custom Contract" Form for products ***
      shownMainComponent = 'ProductForm'
      activeTab = 'PRODUCT'
      if (isCopiedContract) {
        // *** When user has clicked "Copy" on a product contract ***
        if (!hasVehicleRequiredInfo) {
          console.error('ContractFlowPageDetails: Detected that this copied contract has not all required info!')
          notify.warning({ message: t('Product has not all required information') })
        }
      }
    } else if (freeContract && contract.flowType !== 'ADJUST') {
      // *** The "Free/Custom Contract" Form for vehicles ***
      activeTab = 'VEHICLE'
      shownMainComponent = 'VehicleForm'
    } else if (contract.flowType === 'ADJUST' && (freeContract || hasVehicleRequiredInfo)) {
      // *** The Vehicle Info Card/Banner ***
      shownMainComponent = 'VehicleDetails'
    } else if (isCopiedContract) {
      // *** When user has clicked "Copy" on a contract ***
      shownMainComponent = 'VehicleDetails'

      if (!hasVehicleRequiredInfo) {
        console.error('ContractFlowPageDetails: Detected that this copied contract has not all required info!')
        notify.warning({ message: t('Vehicle has not all required information') })
      }
    } else {
      const isUsingV4PricingTool: boolean = provider.isUsingV4PricingTool
      if (isUsingV4PricingTool) {
        shownMainComponent = 'VehicleDetails'
      } else {
        // At startup, if all fields on a vehicle lookup are valid straight from the beginning, show the vehicle details.
        shownMainComponent =
          (vehicleFoundInPricefileAtStartup || wasModelNameReMappedAtStartup) && !freeContract
            ? 'VehicleDetails'
            : 'ModelMapper'
      }
    }

    if (this.props.activeTab !== activeTab && !isCopiedContract) this.props.onActiveTabChange(activeTab)

    this.setState({ shownMainComponent, formModeType })

    // -- IMPORTANT: If ModelMapper, then reset the vehicle on the contract! ----------------------
    //    The vehicle on the contract, must be the one the user just have mapped
    if (shownMainComponent === 'ModelMapper') {
      const temp = {
        ...contract,
        vehicle: undefined,
      }
      this.props.onChange(temp)
    }
    // --------------------------------------------------------------------------------------------
  }

  /**
   * Free contracts should have enabled ALL templates that do not have warranties,
   * but only warranty contracts that are available for chosen vehicle
   */
  private availableTemplatesForFreeContract = (): IGenericContractTemplateResponse[] => {
    const { contract, creationData, freeContract } = this.props
    if (freeContract && creationData) {
      const mapTemplates: Map<number, IGenericContractTemplateResponse> = new Map<
        number,
        IGenericContractTemplateResponse
      >()
      // The order of these for loop are important, since contract.state.templates might have warranty options
      // (depending on vehicle) while creationData.templates has not
      for (const template of creationData.templates) {
        mapTemplates.set(template.id, template)
      }
      for (const template of contract.state.templates) {
        mapTemplates.set(template.id, template)
      }
      return Array.from(mapTemplates.values())
    } else {
      return contract.state.templates
    }
  }

  private getDefaultStartMileageForNewVehicle(vehicle: Vehicle): number | undefined {
    // Vehicles that are no more than 2 months old (a bit arbitrarily) gets DEFAULT_MILEAGE_NEW_VEHICLE
    // km as default value for start mileage, otherwise leave blank
    const maxAgeMonths = 2
    const minRegdateForZeroMileage: Date = new Date()

    minRegdateForZeroMileage.setMonth(minRegdateForZeroMileage.getMonth() - maxAgeMonths)

    const regDate: Date = vehicle.regDate ? new Date(vehicle.regDate) : new Date()
    const startMileage = regDate >= minRegdateForZeroMileage ? DEFAULT_MILEAGE_NEW_VEHICLE : undefined

    return startMileage
  }

  private setWantedDurationValue = (val: IContractFlowMileageDuration): IContractFlowMileageDuration => {
    return {
      ...val,
      max: val.wantedMax,
      value: val.wantedValue,
    }
  }

  private adjustMileageDurationToLimits = (vals: {
    template?: IContractTemplateResponse
    options?: IContractOptionResponse[]
    vehicleAlongItsContracts?: VehicleAlongItsContracts
    startMileage?: number
    duration?: IContractFlowMileageDuration
    mileage?: IContractFlowMileageDuration
    freeContract?: boolean
  }): IDurationMileageValues => {
    const { contract } = this.props
    const template = vals.template || contract.template

    if (!template || !('defaultMileage' in template)) {
      return { mileage: contract.mileage, duration: contract.duration }
    }
    const orgValues = {
      duration: this.setWantedDurationValue(vals.duration || contract.duration),
      mileage: this.setWantedDurationValue(vals.mileage || contract.mileage),
    }

    const freeContract = vals.freeContract !== undefined ? vals.freeContract : this.props.freeContract

    const options = (vals.options || contract.options) as IContractOptionResponse[]
    const vehicle = vals.vehicleAlongItsContracts || contract.vehicleAlongItsContracts
    const startMileage = vals.startMileage !== undefined ? vals.startMileage : contract.startMileage || 0
    const contractStartAge: number = getProductAge(template.calculationMethod, vehicle)

    const maxEndMileageOnContract = adjustSlidersOnContractLimits
      ? template.maxEndMileage || VERY_LARGE_INT
      : VERY_LARGE_INT
    const maxEndAgeOnContract = adjustSlidersOnContractLimits ? template.maxEndAge || VERY_LARGE_INT : VERY_LARGE_INT
    const maxEndMileageOnOptions: number = options.reduce(
      (previousValue, currentValue) =>
        Math.min(previousValue, (currentValue.maxEndMileage || VERY_LARGE_INT) + maxOverMileageStandard),
      VERY_LARGE_INT,
    )
    const maxEndAgeOnOptions: number = options.reduce(
      (previousValue, currentValue) =>
        Math.min(previousValue, (currentValue.maxEndAge || VERY_LARGE_INT) + maxOverAgeStandard),
      VERY_LARGE_INT,
    )
    const maxEndAge = Math.min(maxEndAgeOnContract, maxEndAgeOnOptions)
    const maxEndMileage = Math.min(maxEndMileageOnContract, maxEndMileageOnOptions)

    const maxDuration = freeContract ? VERY_LARGE_INT : maxEndAge - contractStartAge
    const maxMileage = freeContract ? VERY_LARGE_INT : maxEndMileage - startMileage

    const duration = this.adjustedDurationValues(vals.duration || contract.duration, maxDuration)
    const mileage = this.adjustedDurationValues(vals.mileage || contract.mileage, maxMileage)

    // Update mileage value if it is not between the new min and max
    if (
      !freeContract &&
      this.isAxTemplate(contract) &&
      (contract.mileage.value < ((vals.mileage && vals.mileage.min) || contract.mileage.min) ||
        contract.mileage.value > ((vals.mileage && vals.mileage.max) || contract.mileage.max) ||
        orgValues.mileage.value < ((vals.mileage && vals.mileage.min) || contract.mileage.min) ||
        orgValues.mileage.value > ((vals.mileage && vals.mileage.max) || contract.mileage.max))
    ) {
      const duration = this.adjustedDurationValues(vals.duration || contract.duration, maxDuration)
      const mileage = this.adjustedDurationValues(vals.mileage || contract.mileage, maxMileage)

      return { duration, mileage }
    }

    // Currently no limits for free contracts, since the numbers are given i text fields and it's
    // annoying if they change automatically. Instead an error text is given if numbers are out of range.
    if (freeContract || (!adjustSlidersOnContractLimits && !adjustSlidersOnOptionLimits)) {
      return {
        duration: orgValues.duration,
        mileage: orgValues.mileage,
      }
    }

    return { duration, mileage }
  }

  private isAxTemplate = (contract: IContractFlow) => {
    return !!(contract.template && contract.template.serviceVariantId)
  }

  private adjustedDurationValues = (
    values: IContractFlowMileageDuration,
    maxvalue: number,
  ): IContractFlowMileageDuration => {
    if (!values) {
      return { value: 0, min: 0, max: 0, step: 0, wantedMax: 0, wantedValue: 0 }
    }
    if (maxvalue < values.min) {
      return { ...values, value: 0, max: 0 }
    }
    const min = values.min
    const step = values.step
    const maxLimit = Math.min(values.wantedMax, maxvalue)
    const nSteps = step ? Math.floor((maxLimit - min) / step) : 0
    const max = min + nSteps * step
    const value = Math.min(max, Math.max(values.wantedValue, min))

    return { ...values, value, min, max, step }
  }

  private handleVehicleLookupSkip = () => {
    const { contract } = this.props

    this.props.onChange({
      ...contract,
      state: {
        ...contract.state,
        licenseSkip: true,
      },
    })

    this.props.onActivePanelChange(ContractFlowActivePanel.vehicle)
  }

  private handleNoContractsFoundOnReLookup = async (responseError: ResponseErrors) => {
    const { contract } = this.props
    const { provider } = this.state

    // NOTE: Only V4PricingTool supports this "feature".
    if (provider?.isUsingV4PricingTool) {
      debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, 'handleNoContractsFoundOnReLookup: HIT')
      this.props.onActivePanelChange(ContractFlowActivePanel.startMileage)

      this.setState({ hasCalculated: false })

      await this.props.onChange({
        template: undefined, // Deselect any selected template.
        duration: { ...contractFlowInitialState.duration },
        mileage: { ...contractFlowInitialState.mileage },
        payments: { ...contractFlowInitialState.payments },
        state: {
          ...contract.state,
          durations: null,
          mileageDurationsMap: null,
          templates: [],
        },
      })
    }
  }

  /**
   * This is the callback function when making a search on VIN/reg.num.
   *
   * @param vehicleLookedUp The vehicle found with partial (not complete) information.
   * @param vehicleFoundInPricefileAtStartup On the first vehicle lookup (at startup), was the car found in our pricefile.
   */
  private handleVehicleLookupFound = async (
    vehicleLookedUp: VehiclePartial,
    vehicleFoundInPricefileAtStartup: boolean,
    vehicleAlongItsContracts: VehicleAlongItsContracts,
    lookupCountry: TIsoCountry | undefined,
  ) => {
    const { contract } = this.props

    this.setState({ lookupCountry: lookupCountry })

    if (!vehicleFoundInPricefileAtStartup) {
      await this.props.onChange({
        ...contract,
        vehicleLookedUp: vehicleLookedUp,
        product: undefined,
        vehicleAlongItsContracts: undefined,
        state: {
          ...contract.state,
          licenseLookup: true,
          isMileageDisabled: vehicleLookedUp?.vehicleType === 'Caravan' ? true : false,
          vehicleLookedUpFoundInPricefile: vehicleFoundInPricefileAtStartup,
          hasGivenEnginePower: false,
          userGivenEnginePowerKW: null,
        },
      })

      this.handleTemplateChange(undefined) // NOTE: This is important to make the page re-render/update.
    } else {
      const vehicleContracts: VehicleContracts | undefined = vehicleAlongItsContracts.contracts

      const contractDurations = vehicleContracts && vehicleContracts.durations
      const durations: DurationOptions[] = contractDurations || []
      const { duration, mileage } = this.adjustMileageDurationToLimits({ vehicleAlongItsContracts })
      const templates = (vehicleContracts && vehicleContracts.contracts) || []
      const defaultStartMileage = this.getDefaultStartMileageForNewVehicle(vehicleAlongItsContracts)

      const startMileageAlreadyGiven = this.props.contract.startMileage

      await this.props.onChange({
        ...contract,
        startMileage: startMileageAlreadyGiven || defaultStartMileage,
        vehicleLookedUp: vehicleLookedUp,
        product: partialVehicleToVehicle(vehicleLookedUp),
        vehicleAlongItsContracts: vehicleAlongItsContracts,
        duration: duration,
        mileage: mileage,
        payments: { ...contractFlowInitialState.payments },
        state: {
          ...contract.state,
          durations,
          mileageDurationsMap: vehicleContracts?.mileageDurationsMap,
          templates: templates,
          licenseLookup: true,
          isMileageDisabled: vehicleLookedUp?.vehicleType === 'Caravan' ? true : false,
          vehicleLookedUpFoundInPricefile: vehicleFoundInPricefileAtStartup,
        },
      })

      const { wasModelNameReMappedAtStartup } = this.props.contract.state
      const isProceedToNextStep: boolean = wasModelNameReMappedAtStartup || vehicleFoundInPricefileAtStartup

      // If not already at step ContractFlowActivePanel.template, then proceed to that step.
      // NOTE: When doing a re-lookup the step may already be a step after
      // ContractFlowActivePanel.template.
      if (isProceedToNextStep && contract.state.active < ContractFlowActivePanel.template) {
        this.props.onActivePanelChange(ContractFlowActivePanel.template)
      }

      if (templates.length === 1) {
        // console.debug('templates[0]:')
        // console.debug(templates[0])
        this.handleTemplateChange(templates[0])
      }
    }
  }

  private handleTemplatesLookup = async (vehicle: Vehicle, wasModelNameReMappedAtStartup?: boolean) => {
    if (wasModelNameReMappedAtStartup) {
      this.setState({ isLoading: true })
    }

    await this.fetchTemplates(vehicle, wasModelNameReMappedAtStartup)

    this.setState({ isLoading: false })
  }

  private fetchTemplates = async (vehicle: Vehicle, wasModelNameReMappedAtStartup?: boolean) => {
    const { freeContract, contract } = this.props
    const { provider } = this.state

    try {
      if (freeContract && contract.contractObjectType === 'Product') {
        this.setState({ isLoading: true })
        const response = await getProductTemplates()
        this.setState({ isLoading: false })

        if (response.data) {
          this.setContractTemplates(response.data, wasModelNameReMappedAtStartup)
        } else if (!freeContract) {
          notify.warning({ message: t('No contract templates was found for this vehicle') })
          console.warn('ContractFlowPageDetails: No contract templates was found for this vehicle:')
          console.warn(vehicle)
        }
      } else {
        const contactType = contract.contractType
        const startMileage: number = contract.startMileage || 0
        const request = this.mapVehicleToRequest(vehicle, contactType)

        this.setState({ isLoading: true })
        const response = await getTemplatesFromVehicle(request, startMileage)
        this.setState({ isLoading: false })

        if (response.data) {
          const isUsingV4PricingTool: boolean = !!provider?.isUsingV4PricingTool

          if (isCheckPaymentTypesOnAllContractsUponLookup && isUsingV4PricingTool) {
            let contractsMissingAtLeastOnePaymentType: string[] = []

            response.data.contracts.forEach((contract: IContractTemplateResponse) => {
              if (contract?.v4SupportedPaymentTypes.length <= 0) {
                // There must be AT LEAST ONE PaymentType.
                contractsMissingAtLeastOnePaymentType.push(
                  `${contract.serviceVariantId} (${contract.serviceVariantName})`,
                )
              }
            })

            if (contractsMissingAtLeastOnePaymentType.length > 0) {
              let message: string = 'At least one contract template has missing supported-payment-type(s): '
              message += contractsMissingAtLeastOnePaymentType

              console.warn('WARNING: ' + message)
              notify.warning({ message: message })
            }
          }

          this.setContractTemplates(response.data, wasModelNameReMappedAtStartup)
        } else if (!freeContract) {
          notify.warning({ message: t('No contract templates was found for this vehicle') })
          console.warn('ContractFlowPageDetails: No contract templates was found for this vehicle:')
          console.warn(vehicle)
        }
      }
    } catch (err) {
      console.error('ContractFlowPageDetails: Something went wrong fetching contract templates for this vehicle:')
      console.error(vehicle)
    }
  }

  private setContractTemplates = (vehicleContracts: VehicleContracts, wasModelNameReMappedAtStartup?: boolean) => {
    debugPrint(IS_DEBUG_SLIDER_PRINT, '\n-> ***** setContractTemplates(..) ***********\n')

    const { contract } = this.props
    const templates = vehicleContracts.contracts || []

    const durations: DurationOptions[] | null = vehicleContracts.durations
    const mileageDurationsMap: any | null = vehicleContracts.mileageDurationsMap

    if (!durations && !mileageDurationsMap) {
      console.error('No duration mileages found: No durations or mileageDurationsMap')
      throw Error('No duration mileages found for contracts')
    }

    if (durations || mileageDurationsMap) {
      this.props.onChange({
        vehicleLookedUp: contract.vehicleLookedUp,
        state: {
          ...contract.state,
          templates,
          durations: durations,
          mileageDurationsMap: mileageDurationsMap,
          wasModelNameReMappedAtStartup: !!wasModelNameReMappedAtStartup,
        },
      })
    }

    if (templates.length === 1) {
      this.handleTemplateChange(templates[0])
    }
  }

  private mapVehicleToRequest = (vehicle: Vehicle, contractType: ContractType): ITemplateLookupRequest => {
    return {
      contractType: contractType,
      regNo: vehicle.regNumber,
      vin: vehicle.vin!,
      brand: vehicle.brand.name,
      model: vehicle.model.name,
      fuelType: vehicle.fuelType.name,
      regDate: moment(vehicle.regDate).format(defaultDateFormat.moment),
      modelYear: vehicle.modelYear,
      engineMaxPower: vehicle.engineMaxPower || null,
      vehicleType: vehicle.vehicleType || '',
      cylinderVolume: vehicle.cylinderVolume || 0,
      transmissionType: vehicle.transmissionType || '',
      driveType: vehicle.driveType || '',
    }
  }

  private handleVehicleChange = async (vehicleAlongItsContracts: VehicleAlongItsContracts) => {
    const { contract, freeContract } = this.props
    const { shownMainComponent } = this.state
    const contractDurations = vehicleAlongItsContracts.contracts && vehicleAlongItsContracts.contracts.durations
    const durations: DurationOptions[] = contractDurations || []
    const { duration, mileage } = this.adjustMileageDurationToLimits({ vehicleAlongItsContracts })

    // If the vehicle is not valid, then there are no templates.. preserve the old template list.
    const templates = hasRequiredInfo(vehicleAlongItsContracts)
      ? (vehicleAlongItsContracts.contracts && vehicleAlongItsContracts.contracts.contracts) || []
      : this.props.contract.state.templates

    const startMileage =
      contract.startMileage !== undefined
        ? contract.startMileage
        : this.getDefaultStartMileageForNewVehicle(vehicleAlongItsContracts)

    if (
      (freeContract || shownMainComponent === 'ModelMapper') &&
      vehicleAlongItsContracts &&
      vehicleAlongItsContracts.brand.name &&
      (!contract.vehicleAlongItsContracts ||
        vehicleAlongItsContracts.brand.name !== contract.vehicleAlongItsContracts.brand.name ||
        vehicleAlongItsContracts.model.name !== contract.vehicleAlongItsContracts.model.name ||
        vehicleAlongItsContracts.fuelType.name !== (contract.vehicleAlongItsContracts.fuelType?.name || ''))
    ) {
      const { brand, model, fuelType } = vehicleAlongItsContracts
      const res = await lookupVehicleBlacklist({ brand: brand.name, model: model.name, fuelType: fuelType.name })

      if (res.data !== undefined && res.data !== null) {
        res.data && notify.warning({ message: t(errors['REGNO_NO_CONTRACTS_AVAILABLE_BLACKLISTED']) })
        this.setState({ vehicleIsBlacklisted: res.data }, () => {
          if (contract.template) {
            this.handleTemplateChange(undefined)
          }
        })
      }
    }

    const temp = {
      ...contract,
      template: undefined,
      startMileage,
      vehicleLookedUp: contract.vehicleLookedUp,
      product: vehicleAlongItsContracts,
      vehicleAlongItsContracts: vehicleAlongItsContracts,
      duration,
      mileage,
      state: {
        ...contract.state,
        durations,
        licenseLookup: true,
        isMileageDisabled: contract?.vehicleLookedUp?.vehicleType === 'Caravan' ? true : false,
      },
    }
    this.props.onChange(temp)

    if (templates.length === 1) {
      // If there is only one entry among the (new) Templates found, select it.
      this.handleTemplateChange(templates[0])
    } else if (templates.length > 0) {
      this.handleTemplateChange(undefined)
    }

    if (contract.state.licenseSkip && hasRequiredInfo(vehicleAlongItsContracts, true)) {
      // If licenseSkip, aka ManualSearch - then VIN is not required.
      this.props.onActivePanelChange(ContractFlowActivePanel.template) // Proceed to next panel.
    } else if (hasRequiredInfo(vehicleAlongItsContracts)) {
      // If normal/default scenario.
      this.props.onActivePanelChange(ContractFlowActivePanel.template) // Proceed to next panel.
    }
  }

  private handleProductChange = (productAlongItsContracts: ProductAlongItsContracts) => {
    const { contract } = this.props
    const contractDurations = productAlongItsContracts.contracts && productAlongItsContracts.contracts.durations
    const durations: DurationOptions[] = contractDurations || []

    // If the product is not valid, then there are no templates.. preserve the old template list.
    const templates = productHasRequiredInfo(productAlongItsContracts)
      ? (productAlongItsContracts.contracts && productAlongItsContracts.contracts.contracts) || []
      : this.props.contract.state.templates

    const startValue = contract.startValue !== undefined ? contract.startValue : 0

    const temp = {
      ...contract,
      startValue,
      vehicleLookedUp: contract.vehicleLookedUp,
      product: productAlongItsContracts,
      vehicleAlongItsContracts: productAlongItsContracts,
      state: {
        ...contract.state,
        durations,
        licenseLookup: true,
        isMileageDisabled: contract?.vehicleLookedUp?.vehicleType === 'Caravan' ? true : false,
      },
    }
    this.props.onChange(temp)

    if (templates.length === 1) {
      // If there is only one entry among the (new) Templates found, select it.
      this.handleTemplateChange(templates[0])
    } else if (templates.length > 0) {
      this.handleTemplateChange(undefined)
    }

    if (
      this.props.activePanel < ContractFlowActivePanel.template &&
      contract.state.licenseSkip &&
      productHasRequiredInfo(productAlongItsContracts, true)
    ) {
      // If licenseSkip, aka ManualSearch - then VIN is not required.
      this.props.onActivePanelChange(ContractFlowActivePanel.template) // Proceed to next panel.
    } else if (
      this.props.activePanel < ContractFlowActivePanel.template &&
      productHasRequiredInfo(productAlongItsContracts)
    ) {
      // If normal/default scenario.
      this.props.onActivePanelChange(ContractFlowActivePanel.template) // Proceed to next panel.
    }
  }

  private handleTemplateChange = (
    template: IContractTemplateResponse | IProductContractTemplateResponse | undefined,
    customContract?: IContractFlow,
  ) => {
    debugPrint(IS_DEBUG_SLIDER_PRINT, '\n-> ***** handleTemplateChange(..) ***\n')

    const { vehicleIsBlacklisted, provider } = this.state

    template = !vehicleIsBlacklisted ? (template as IContractTemplateResponse | undefined) : undefined

    if (provider?.isUsingV4PricingTool && template && template?.v4SupportedPaymentTypes.length < 1) {
      let message: string = `This contract template has no supported-payment-type(s), V4-Product-ID: ${template.serviceVariantId}, Name: ${template.serviceVariantName}`

      console.warn('WARNING: ' + message)
      notify.warning({ message: message })
    }

    const contract = customContract ? customContract : this.props.contract
    const startMileage = template && template.calculationMethod > 100 ? true : false

    // Get duration / mileage object.
    const { durations, mileageDurationsMap } = contract.state

    if (provider?.isUsingV4PricingTool) {
      if (!mileageDurationsMap || getMapSize(mileageDurationsMap) <= 0) {
        console.warn(`No duration/mileage map found for V4PT templates`)
        notify.error({ message: t('Missing price data or response from V4PT') })
        return
      }
      assert(
        !!mileageDurationsMap && getMapSize(mileageDurationsMap) > 0,
        `No duration/mileage map found for V4PT templates`,
      )
    } else {
      if (!durations) {
        console.warn(`No duration/mileage object found for non V4PT templates`)
      }
    }

    // We have to intervene here and run the options through since we
    // want the default status for free options to be selected.
    const selectedOptions = template ? template.options.filter((opt) => opt.price.price === 0) : []

    const durationList = getDurationList(durations!, template)
    const duration = getDurationValues(durationList)
    const wantedDuration = contract.duration.value || (template && template.defaultDuration) || 0
    const durationValue = getClosestAllowedValue(wantedDuration, durationList)

    let adjustedValues = null

    // Calculated adjustment values are tured off for non vehicle templates
    // since it's not supported outside of free contracts
    if (!this.props.freeContract && contract.contractObjectType === 'Vehicle') {
      console.info('*** VEHICLE and NON-Custom contract ***')

      const mileageList = getMileageList(contract.state.durations!, durationValue, template)
      const mileage = getMileageValues(mileageList)
      const wantedMileage = contract.mileage.value || (template && template.defaultMileage) || 0
      const mileageValue = getClosestAllowedValue(wantedMileage, mileageList)

      adjustedValues = this.adjustMileageDurationToLimits({
        template,
        duration: { ...duration, value: durationValue, wantedValue: durationValue },
        mileage: { ...mileage, value: mileageValue, wantedValue: mileageValue },
      })

      adjustedValues = { ...adjustedValues, value: adjustedValues.mileage }
    } else {
      console.info('*** Free/Custom Contract - Keep the old values ***')

      // If this is a "Free/Custom Contract" keep the old values.
      adjustedValues = {
        duration: contract.duration,
        mileage: contract.mileage,
        value: contract.value,
      }

      const hasSelectedTemplate = !!contract.template
      this.props.freeContract && hasSelectedTemplate && this.setContractMileage(contract.startMileage, template) // NOTE: Only do this in Free/Custom contract scenario.
      setTimeout(this.handleStartMileageChange, 500, contract.startMileage) // Third parameter is sent to the internal function at the end of the timer.
    }

    const payments: ICustomPrice = {
      ...contract.payments,
      isDownpaymentDistributed: false,
    }

    let values: Partial<IContractFlow> = {
      ...contract,
      template,
      contractStartDateISO: null,
      options: selectedOptions,
      state: {
        ...contract.state,
        options: template ? template.options : [],
        startMileage,
      },
      v4ProductType: template?.v4ProductType || null,
      v4SupportedPaymentTypes: template?.v4SupportedPaymentTypes || [],
    }

    if (!provider?.isUsingV4PricingTool) {
      values = {
        ...values,
        duration: adjustedValues.duration || 12,
        mileage: adjustedValues.mileage || 60000,
        value: adjustedValues.value,
      }
    }

    if (template && template.calculationMethod !== 100) {
      this.props.onChange({ ...values, payments })
    } else {
      this.props.onChange({ ...values })
    }

    if (template) {
      this.props.onActivePanelChange(ContractFlowActivePanel.options)
    } else {
      if (contract.vehicleAlongItsContracts && this.productHasIdentifiers(contract.vehicleAlongItsContracts)) {
        this.props.onActivePanelChange(ContractFlowActivePanel.template)
      } else {
        this.props.onActivePanelChange(ContractFlowActivePanel.vehicle)
      }
    }

    if (this.props.freeContract && !!template) {
      this.props.onActivePanelChange(ContractFlowActivePanel.options)
    }
  }

  private productHasIdentifiers = (
    productAlongItsContracts: VehicleAlongItsContracts | ProductAlongItsContracts,
  ): boolean => {
    const vehicleHasRegAndVin = 'vin' in productAlongItsContracts && hasVinAndRegNum(productAlongItsContracts)
    const productHasItemAndSerial =
      'itemNumber' in productAlongItsContracts && hasItemSerialNumber(productAlongItsContracts)

    return !!vehicleHasRegAndVin || !!productHasItemAndSerial
  }

  private handleStartMileageChange = (startMileage: number | undefined) => {
    const { contract, freeContract } = this.props
    const { provider } = this.state

    const startMileageRequired = contract.template && contract.template.calculationMethod > 100 ? true : false

    let active = ContractFlowActivePanel.template
    if (!startMileageRequired || startMileage !== undefined) {
      const validDurationMileage = this.isDurationMileageValid()

      if (validDurationMileage && contract.template) {
        active = ContractFlowActivePanel.options
      } else if (contract.template) {
        active = ContractFlowActivePanel.durationMileage
      }
    }

    let values: Partial<IContractFlow> = {}

    if (!provider?.isUsingV4PricingTool) {
      // Below line with "adjusted" values are only for the "old / legacy" duration/mileage-slider (VER1) that is used on non-V4PT contracts.
      const { duration, mileage } = this.adjustMileageDurationToLimits({ startMileage }) // Note: These "adjusted" values are only for duration/mileage-slider (VER1), not for the new (VER2)!

      values = {
        startMileage,
        duration,
        mileage,
        startValue: startMileage,
        state: {
          ...contract.state,
          active,
        },
      }
    } else {
      values = {
        startMileage,
        startValue: startMileage,
        state: {
          ...contract.state,
          active,
        },
      }
    }
    this.props.onChange(values)

    const hasSelectedTemplate = !!contract.template
    freeContract && hasSelectedTemplate && this.setContractMileage(startMileage, contract.template) // NOTE: Only do this in Free/Custom contract scenario.
  }

  private handleStartValueChange = (value: number | undefined) => {
    const { contract } = this.props

    let active = ContractFlowActivePanel.template
    const startValuesRequired = contract.template && contract.template.calculationMethod > 100 ? true : false
    if (!startValuesRequired || value !== undefined) {
      const validDurationHourService = this.isDurationHourServiceValid()

      if (validDurationHourService) {
        active = ContractFlowActivePanel.options
      } else if (active >= ContractFlowActivePanel.template && contract.template) {
        active = ContractFlowActivePanel.durationMileage
      }
    }

    this.props.onChange({
      startValue: value,
      valueType: contract.valueType,
      payments: { ...contractFlowInitialState.payments },
      state: {
        ...contract.state,
        active,
      },
    })
  }

  private handleValueTypeChange = (startValueType: ContractValueType) => {
    const { contract } = this.props

    let active = ContractFlowActivePanel.template
    const startValuesRequired = contract.template && contract.template.calculationMethod > 100 ? true : false

    if (!startValuesRequired || startValueType !== undefined) {
      const validDurationHourService = this.isDurationHourServiceValid()

      if (validDurationHourService) {
        active = ContractFlowActivePanel.options
      } else if (active >= ContractFlowActivePanel.template && contract.template) {
        active = ContractFlowActivePanel.durationMileage
      }
    }

    // Don't sync with contract.valueType if it has been cleared or detached
    const valueType =
      !detachedValueTypes.includes(contract.valueType) && !this.state.detachedValueType
        ? startValueType
        : contract.valueType

    this.props.onChange({
      valueType: valueType,
      startValueType: startValueType,
      startValue: startValueType === 'Services' ? 0 : contract.startValue,
      state: {
        ...contract.state,
        active,
      },
    })
  }

  private isDurationMileageValid = () => {
    const { contract } = this.props

    const duration = contract.duration.value
    const mileage = contract.mileage.value

    return duration && mileage
  }

  private isDurationHourServiceValid = () => {
    const { contract } = this.props

    const duration = contract.duration.value
    const valueType = contract.valueType

    return duration && ((valueType && contract.value) || !contract.valueType)
  }

  private getNextButtonDisabledWarning = (allDataAvailable: boolean): string => {
    const { contract, freeContract } = this.props
    const { template, payments, invalidPaymentData } = contract

    const isMileageDisabled: boolean = !!contract?.state?.isMileageDisabled

    // If not all data available then the next button will be disabled anyway without message
    if (!allDataAvailable) return ''

    const CK_WARRANTY_NEEDS_REGNO = 'For warranty contracts the registration number must be given'
    const CK_VEHICLE_ALREADY_HAS_WARRANTY =
      'You can not create a contract type or option with warranty because there already exists a warranty on the vehicle'
    const CK_VEHICLE_AGE_OVER_MAX_AGE_FOR_OPTION =
      "The vehicle's age is larger than the max age for the selected warranty option"
    const CK_VEHICLE_MILEAGE_OVER_MAX_MILEAGE_FOR_OPTION =
      "The vehicle's current mileage is larger than the max mileage for the selected warranty option"
    const CK_MISSING_START_MILEAGE = 'With the chosen template a start mileage must be given'
    const CK_VEHICLE_AGE_OVER_MAX_AGE_FOR_CONTRACT =
      "The vehicle's age is larger than the max age for the selected warranty contract"
    const CK_VEHICLE_MILEAGE_OVER_MAX_MILEAGE_FOR_CONTRACT =
      "The vehicle's current mileage is larger than the max mileage for the selected warranty contract"
    const CK_DURATION_OVER_MAX_AGE_BEFORE_END_OF_CONTRACT =
      'With the chosen template and duration the maximum allowed age will be reached before the end of the contract. Please select another contract template or shorter the duration, if possible'
    const CK_MILEAGE_OVER_MAX_MILEAGE_BEFORE_END_OF_CONTRACT =
      'With the chosen template and mileage the maximum allowed mileage will be reached before the end of the contract. Please select another contract template or lower the mileage, if possible'
    const CK_WARRANTY_ALREADY_CREATED =
      'You can not create a contract type or option with warranty because you have already created a warranty for this vehicle'
    const CK_CONTRACT_TOO_SHORT = 'The end of the contract is less than 12 months away. Try to set a longer duration'
    const CK_INVALID_PAYMENT_DATA = 'Invalid payment data'

    if (invalidPaymentData) {
      return tDot(CK_INVALID_PAYMENT_DATA)
    }

    const startAge = getContractProductAge(contract)
    const contractHasWarranty = this.contractHasWarranty()

    if (contractHasWarranty && 'vin' in this.props.contract.product && !this.props.contract.product.regNumber) {
      return tDot(CK_WARRANTY_NEEDS_REGNO)
    }

    if (contractHasWarranty && this.props.warrantyCreated) {
      return tDot(CK_WARRANTY_ALREADY_CREATED)
    }

    if (contractHasWarranty && this.vehicleHasWarranty()) {
      return tDot(CK_VEHICLE_ALREADY_HAS_WARRANTY)
    }

    const options = contract.options as IProductContractOptionResponse[]

    for (const o of options) {
      if (o.maxEndAge && startAge - maxOverAgeCustom > o.maxEndAge) {
        return tDot(CK_VEHICLE_AGE_OVER_MAX_AGE_FOR_OPTION)
      }
      if (o.maxEndHours && ((!isMileageDisabled && contract.startMileage) || 0) > o.maxEndHours) {
        return tDot(CK_VEHICLE_MILEAGE_OVER_MAX_MILEAGE_FOR_OPTION)
      }
    }

    const contractEndDate = this.dateStringToDateOrUndefined(payments.contractEndDate)
    if (contractEndDate && contractEndDate < addMonths(new Date(), 12)) {
      return tDot(CK_CONTRACT_TOO_SHORT)
    }

    if (freeContract) {
      for (const o of options) {
        if (!isMileageDisabled && o.maxEndAge && startAge - maxOverAgeCustom > o.maxEndAge) {
          return tDot(CK_VEHICLE_AGE_OVER_MAX_AGE_FOR_OPTION)
        }
        if (o.maxEndHours && ((!isMileageDisabled && contract.startMileage) || 0) > o.maxEndHours) {
          return tDot(CK_VEHICLE_MILEAGE_OVER_MAX_MILEAGE_FOR_OPTION)
        }
      }
    }

    if (!template || !('defaultMileage' in template)) {
      return ''
    }

    const startMileage: number | undefined = this.getContractVehicleStartMileage(contract)
    if ((!isMileageDisabled && startMileage === undefined) || startMileage === 0) {
      // 'With the chosen template a start mileage must be given'
      return tDot(CK_MISSING_START_MILEAGE)
    }
    if (template.maxEndAge && startAge > template.maxEndAge) {
      // "The vehicle's age is larger than the max age for the selected warranty contract"
      return tDot(CK_VEHICLE_AGE_OVER_MAX_AGE_FOR_CONTRACT)
    }
    if (!isMileageDisabled && template.maxEndMileage && (contract.startMileage || 0) > template.maxEndMileage) {
      // "The vehicle's current mileage is larger than the max mileage for the selected warranty contract"
      return tDot(CK_VEHICLE_MILEAGE_OVER_MAX_MILEAGE_FOR_CONTRACT)
    }
    if (template.maxEndAge && contract.duration.value + startAge > template.maxEndAge) {
      // 'With the chosen template and duration the maximum allowed age will be reached before the end of the contract. Please select another contract template or shorter the duration, if possible'
      return tDot(CK_DURATION_OVER_MAX_AGE_BEFORE_END_OF_CONTRACT)
    }
    if (
      !isMileageDisabled &&
      template.maxEndMileage &&
      contract.mileage.value + (startMileage || 0) > template.maxEndMileage
    ) {
      // 'With the chosen template and mileage the maximum allowed mileage will be reached before the end of the contract. Please select another contract template or lower the mileage, if possible'
      return tDot(CK_MILEAGE_OVER_MAX_MILEAGE_BEFORE_END_OF_CONTRACT)
    }

    return ''
  }

  private getContractVehicleStartMileage(contract: IContractFlow): number | undefined {
    if (contract?.state?.isMileageDisabled) {
      return undefined
    }

    const calculationMethodFirstRegDate = 100
    if (contract.template && contract.template.calculationMethod === calculationMethodFirstRegDate) {
      return 0
    } else {
      return contract.startMileage
    }
  }

  /**
   * NOTE: This method is for VERSION 1 of ContractFlowDurationMileage.
   */
  private handleDurationMileageVer1Change = (
    values: {
      duration: IContractFlowMileageDuration
      mileage: IContractFlowMileageDuration
    },
    valid: boolean,
  ) => {
    const { contract } = this.props
    values = this.adjustMileageDurationToLimits(values)

    const active = valid ? ContractFlowActivePanel.options : ContractFlowActivePanel.durationMileage
    const newState: Partial<IContractFlow> = {
      ...values,
      value: values.mileage,
      state: {
        ...contract.state,
        active,
      },
    }

    if (contract.payments.providerPayments >= values.duration.value) {
      const newMaxProviderPayments = values.duration.value - 1
      newState.payments = {
        ...contract.payments,
        // Update to new max provider payments
        providerPayments: newMaxProviderPayments || 0,
      }
    }

    this.props.onChange(newState as Partial<IContractFlow>)
  }

  /**
   * NOTE: This method is for VERSION 2 of ContractFlowDurationMileage.
   * NOTE2: It is assumed that the data passed to this function are correct and valid
   * (the data should be checked in the method calling this function!)
   */
  private handleDurationMileageVer2Change = (
    selectedMileageKmData: IContractFlowMileageDuration,
    selectedDurationMonthsData: IContractFlowMileageDuration,
  ) => {
    console.debug('*v2')
    const { contract } = this.props

    const isValid = true
    const active = isValid ? ContractFlowActivePanel.options : ContractFlowActivePanel.durationMileage
    const newState: Partial<IContractFlow> = {
      ...contract,
      duration: {
        ...selectedDurationMonthsData,
      },
      mileage: {
        ...selectedMileageKmData,
      },
      value: {
        ...selectedMileageKmData,
      },
      state: {
        ...contract.state,
        active,
      },
      payments: { ...contractFlowInitialState.payments },
    }

    this.props.onChange(newState)
  }

  private handleDurationHourServiceChange = (
    values: {
      duration: IContractFlowMileageDuration
      selectedValue: IContractFlowMileageDuration
      valueType: ContractValueType
    },
    valid: boolean,
    valueTypeIgnored: boolean,
  ) => {
    const { contract } = this.props

    const active = valid ? ContractFlowActivePanel.options : ContractFlowActivePanel.durationMileage
    const newState: Partial<IContractFlow> = {
      value: values.selectedValue,
      valueType: values.valueType,
      duration: values.duration,
      state: {
        ...contract.state,
        active,
      },
    }

    if (contract.payments.noPayment) {
      newState.payments = {
        ...contract.payments,
        providerPayments: values.duration.value,
      }
    }

    if (valueTypeIgnored !== this.state.detachedValueType) {
      this.setState({ detachedValueType: valueTypeIgnored })
    }

    if (!values.valueType && detachedValueTypes.includes(contract.startValueType)) {
      newState.startValueType = values.valueType
    } else if (values.valueType && !detachedValueTypes.includes(values.valueType)) {
      newState.startValueType = values.valueType
    }

    this.props.onChange(newState)
  }

  private handleOptionsChange = (options: IContractOptionResponse[]) => {
    const { duration, mileage } = this.adjustMileageDurationToLimits({ options })
    this.props.onChange({ options, duration, mileage })
  }

  private handleResetContract = () => {
    this.props.onResetContract()
  }

  private handleWarrantyCreated = () => {
    this.props.onCustomerChange(this.props.contract.customer, true)
    this.props.onCustomerLockedChange(true)
    this.props.onWarrantyCreated()
  }

  private handleStartDateChange = (startDate: Date) => {
    if (!validDate(startDate.toISOString())) {
      console.error('handleStartDateChange: Invalid date: ' + startDate)
    }

    const values = {
      contractStartDateISO: startDate,
    }
    this.props.onChange(values)
  }

  private contractHasWarranty = (): boolean => {
    const { contract } = this.props
    const { template } = contract
    if (template && template.properties.some((p) => (p.warranty ? true : false))) {
      return true
    }
    return contract.options.some((o) => (o.warranty ? true : false))
  }

  private vehicleHasWarranty = (): boolean => {
    const { vehicleAlongItsContracts } = this.props.contract

    return vehicleAlongItsContracts.hasActiveWarranty === true
  }

  private dateStringToDateOrUndefined = (str: string): Date | undefined => {
    try {
      return new Date(str) || undefined
    } catch (err) {
      return undefined
    }
  }

  private readyForCalculation = (): boolean => {
    const IS_DEBUG: boolean = false // Default is false.
    const { loading, freeContract, contract } = this.props
    const hasCalculated: boolean = this.state.hasCalculated

    //console.debug('contract:')
    //console.debug(JSON.stringify(contract))
    const isReady: boolean = isContractReadyForCalculation(contract, loading, freeContract, hasCalculated)

    if (hasCalculated !== isReady) {
      console.debug('hasCalculated: ' + hasCalculated)
      console.debug('isReady: ' + isReady)

      this.setState({ hasCalculated: isReady })
    }

    debugPrint(IS_DEBUG, `readyForCalculation(..): will return value: ${isReady}`)
    return isReady
  }

  private getRemainingMileage = (
    startMileage: number | undefined,
    template: IContractTemplateResponse | IProductContractTemplateResponse | undefined,
  ): number => {
    const { contract } = this.props

    assert(!!contract.state.durations, 'No duration mileage object found for non-V4PT')

    const durationList = getDurationList(contract.state.durations!, template as IContractTemplateResponse)
    const wantedDuration = contract.duration.value || (template && template.defaultDuration) || 0
    const durationValue = getClosestAllowedValue(wantedDuration, durationList)
    const mileageList = getMileageList(contract.state.durations!, durationValue, template as IContractTemplateResponse)

    const highestDurationMileage: number = mileageList[mileageList.length - 1] // NOTE: Vehicles with no PriceModel, will not have any correct durationMileage (=0).
    const templateMaxEndMileage: number = (template && (template as IContractTemplateResponse).maxEndMileage) || 0

    const highest: number = Math.max(highestDurationMileage, templateMaxEndMileage)
    const remainingMileage = highest - (startMileage || 0)

    return remainingMileage
  }

  private setContractMileage = (
    startMileage: number | undefined,
    template: IContractTemplateResponse | IProductContractTemplateResponse | undefined,
  ): void => {
    const { contract } = this.props
    const remainingMileage = this.getRemainingMileage(startMileage, template)

    this.setState({
      defaultContractMileageFreeContract: remainingMileage,
    })

    this.props.onChange({
      mileage: {
        ...contract.mileage,
        value: remainingMileage,
        wantedValue: remainingMileage,
      },
    })
  }
}

export default withStyles(styles)(ContractFlowPageDetails)
